import { Container } from '@components/Container/Container'
import { IconFacebook } from '@components/Icons/IconFacebook'
import { IconLinkedin } from '@components/Icons/IconLinkedin'
import styles from '../Footer/footer.module.scss'

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container additionalStyles={styles.wrapper}>
        <div className={styles.text}>
          <h3>WHO YA GONNA CALL?</h3>
          <p>
            Feel free to get in touch if you need a quotation, design
            consultancies, expert workforce for your projects, or if you want to
            hang out for a beer, in any case you are more than welcome.
          </p>
        </div>
        <div className={styles.contacts}>
          <h3>CONTACT ME</h3>
          <a href="mailto:marioveltri.ui@gmail.com?subject=marioveltri.com">
            marioveltri.ui@gmail.com
          </a>
          <div className={styles.icons}>
            <a
              aria-label="facebook link"
              href="https://www.facebook.com/theartofmarioveltri"
            >
              <IconFacebook additionalStyles={styles.icon} />
            </a>
            <a
              aria-label="linkedin link"
              href="https://www.linkedin.com/in/marioveltri"
            >
              <IconLinkedin additionalStyles={styles.icon} />
            </a>
          </div>
        </div>
        <div className={styles.copyright}>
          <p className={styles.idr}>
            made with{' '}
            <span aria-label="heart emoji" role="img">
              ❤️
            </span>{' '}
            from <a href="http://www.idrinkritalin.it">idrinkritalin.it</a> -
            Copyright © 2021 All rights reserved
          </p>
        </div>
      </Container>
    </footer>
  )
}
