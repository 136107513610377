import { SVGAttributes, ReactNode } from 'react'

interface IconWrapperProps extends SVGAttributes<SVGElement> {
  title: string
  children: ReactNode
}

export const IconWrapper = (props: IconWrapperProps) => {
  const { title, children, ...rest } = props
  return (
    <svg
      focusable={false}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      role="img"
      aria-label={title}
      {...rest}
    >
      <title>{title}</title>
      {children}
    </svg>
  )
}
