import { IconWrapper } from './IconWrapper'
import classnames from 'classnames'

interface IconFacebookProps {
  additionalStyles?: string
  fill?: string
}

export const IconFacebook = ({
  additionalStyles,
  fill = '#ffffff',
}: IconFacebookProps) => {
  return (
    <IconWrapper
      className={additionalStyles ?? classnames(additionalStyles)}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill={fill}
      title="Icon/Facebook"
    >
      <path
        d="M12.5 0C5.597 0 0 5.62 0 12.552 0 18.77 4.507 23.92 10.415 24.916v-9.745H7.4v-3.507h3.015V9.078c0-3 1.825-4.635 4.49-4.635 1.278 0 2.375.096 2.693.138v3.136H15.75c-1.449 0-1.728.692-1.728 1.707v2.238h3.458l-.451 3.507H14.02V25C20.205 24.244 25 18.964 25 12.549 25 5.619 19.403 0 12.5 0z"
        transform="translate(-620 -4294) translate(0 4093) translate(620 70) translate(0 131)"
      />
    </IconWrapper>
  )
}
