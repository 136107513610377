import { useState } from 'react'
import { useRouter } from 'next/router'
import classnames from 'classnames'
import { motion } from 'framer-motion'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Link from 'next/link'
import { IconLogo } from '@components/Icons/IconLogo'
import { Container } from '@components/Container/Container'
import { HeaderMenu } from './HeaderMenu'
import { useWindowSize } from '@utils/useWindowSize'
import styles from '../Header/header.module.scss'

export const Header = () => {
  const router = useRouter()
  const windowWidth = useWindowSize().width
  const [isMenuOpen, setMenuOpen] = useState(false)
  const isHomePage: boolean = router.pathname === '/'
  const navVariants = {
    hidden: { display: 'none', opacity: 0 },
    visible: { height: 'auto', opacity: 1 },
  }

  return (
    <header id="header" className={styles.header}>
      <Container additionalStyles={styles.wrapper}>
        <div className={styles.logo}>
          <Link legacyBehavior href="/">
            <a href="/">
              <h1 className="screenReader">Mario Veltri</h1>
              <IconLogo />
            </a>
          </Link>
        </div>
        <motion.nav
          className={classnames(
            styles.navigation,
            !isHomePage && styles.mobile
          )}
          initial={windowWidth <= 768 && !isMenuOpen && 'hidden'}
          animate={windowWidth <= 768 && isMenuOpen ? 'visible' : 'hidden'}
          variants={windowWidth <= 768 && navVariants}
          transition={{ duration: 1 }}
        >
          <Link legacyBehavior href="/">
            <a href="/">Home</a>
          </Link>
          {isHomePage && <AnchorLink href="#about">About</AnchorLink>}
          <Link legacyBehavior href="/portfolio">
            <a
              href="/portfolio"
              className={classnames(
                router.pathname === '/portfolio' && styles.active
              )}
            >
              Portfolio
            </a>
          </Link>
          {isHomePage && (
            <>
              <AnchorLink href="#skills">Skills</AnchorLink>
              <AnchorLink href="#contacts">Contacts</AnchorLink>
            </>
          )}
        </motion.nav>
        <HeaderMenu
          isOpen={isMenuOpen}
          onClick={() => setMenuOpen(!isMenuOpen)}
        />
      </Container>
    </header>
  )
}
