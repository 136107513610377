import { IconWrapper } from './IconWrapper'
import classnames from 'classnames'

interface IconLinkedinProps {
  additionalStyles?: string
  fill?: string
}

export const IconLinkedin = ({
  additionalStyles,
  fill = '#ffffff',
}: IconLinkedinProps) => {
  return (
    <IconWrapper
      className={additionalStyles ?? classnames(additionalStyles)}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill={fill}
      title="Icon/Linkedin"
    >
      <path
        d="M48 0c-7.179 0-13 5.821-13 13s5.821 13 13 13 13-5.821 13-13S55.179 0 48 0zm-4 20h-3V10h3v10zM42.988 9h-.023C41.772 9 41 8.333 41 7.5c0-.852.795-1.5 2.012-1.5 1.216 0 1.965.648 1.988 1.5 0 .833-.772 1.5-2.012 1.5zM56 20h-3.276v-5.227c0-1.314-.474-2.21-1.66-2.21-.904 0-1.443.605-1.68 1.188-.087.209-.108.5-.108.793V20H46s.043-8.854 0-9.77h3.276v1.383C49.71 10.947 50.49 10 52.228 10 54.384 10 56 11.396 56 14.398V20z"
        transform="translate(-655 -4294) translate(0 4093) translate(620 70) translate(0 131)"
      />
    </IconWrapper>
  )
}
