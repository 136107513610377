import { IconWrapper } from './IconWrapper'
import classnames from 'classnames'

interface IconArrowUpProps {
  additionalStyles?: string
  fill?: string
}

export const IconArrowUp = ({
  additionalStyles,
  fill = '#ffffff',
}: IconArrowUpProps) => {
  return (
    <IconWrapper
      className={additionalStyles ?? classnames(additionalStyles)}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill={fill}
      title="Icon/ArrowUp"
    >
      <path
        d="M27.655 14.4L19.834 22.235 12.014 14.4 9.6 16.818 19.834 27.071 30.069 16.818z"
        transform="translate(-1355 -4386) translate(1346 4372) rotate(-180 19.834 20.736)"
      />
    </IconWrapper>
  )
}
