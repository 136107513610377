// import { useState } from 'react'
import { motion /* , useScroll */ } from 'framer-motion'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { IconArrowUp } from '@components/Icons/IconArrowUp'
import styles from './scrollTop.module.scss'

export const ScrollTop = () => {
  // TODO: check
  // const [offsetY, setOffsetY] = useState(0)
  // const { scrollYProgress } = useScroll()
  // scrollYProgress.onChange((y) => setOffsetY(y))

  return (
    <motion.div
      className={styles.scrollTop}
      initial={{ x: 200 }}
      // animate={offsetY > 0.1 ? { x: 0, rotate: 360 } : { x: 200, rotate: 0 }}
      transition={{ duration: 1 }}
    >
      <AnchorLink href="#header" className={styles.icon}>
        <IconArrowUp />
      </AnchorLink>
    </motion.div>
  )
}
