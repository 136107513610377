import { ReactNode } from 'react'
import classnames from 'classnames'
import styles from '../Container/container.module.scss'

interface ContainerProps {
  children: ReactNode
  additionalStyles?: string
}

export const Container = ({ children, additionalStyles }: ContainerProps) => {
  return (
    <div className={classnames(styles.wrapper, additionalStyles)}>
      {children}
    </div>
  )
}
